import { useCallback, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RouteChangeListener from "src/components/RouteChangeListener";

import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HelmetProvider } from "react-helmet-async";

import WithLayout from "./components/WithLayout";
import LoginLayout from "./layouts/LoginLayout";
import ComplianceGuideLayout from "./layouts/ComplianceGuideLayout";

import PoliciesSent from "./pages/Reports/PoliciesSent";
import InvestigatePhoneNumber from "./pages/Reports/InvestigatePhoneNumber";
import Users from "./pages/Users/Users";
import AddUser from "./pages/Users/AddUser";
import Tcl from "./components/Tcl";
import NotFound from "src/components/NotFound";
import Error from "src/components/Error";

import CGIntro from "./pages/ComplianceGuide/Index";
import CGRegulatoryGuide from "./pages/ComplianceGuide/RegulatoryGuide/Index";
import CGCharts from "./pages/ComplianceGuide/Charts/Index";
import CGRegulatoryWatch from "./pages/ComplianceGuide/RegulatoryWatch/Index";
import CGDNCMap from "./pages/ComplianceGuide/DNCMap/Index";
import CGStatutes from "./pages/ComplianceGuide/Statutes/Index";
import CGFaqIndex from "./pages/ComplianceGuide/FAQ/Index";
import CGComplianceWizard from "./pages/ComplianceGuide/ComplianceWizard/Index";
import CGGlossary from "./pages/ComplianceGuide/Glossary/Index";
import CGConsentChart from "./pages/ComplianceGuide/ConsentChart/Index";
import CGLitigatorResearch from "./pages/ComplianceGuide/LitigatorResearch/Index";
import CGLitigatorScrub from "./pages/ComplianceGuide/LitigatorScrub/Index";
import CGSubmitLitigator from "./pages/ComplianceGuide/SubmitLitigator/Index";
import CGOtherTools from "./pages/ComplianceGuide/OtherTools/Index";
import CGFTCComplaintSearch from "./pages/ComplianceGuide/FTCComplaintSearch/Index";
import UploadListMain from "./pages/Main/Main";
import useInactivity from "./hooks/useInactivity";
import { logout, setIsAuthenticated } from "./features/authSlice";
import { useLogoutUserMutation } from "./services/authApi";
import { callApi, HttpMethod } from "./services/apiService";
import { useAppDispatch } from "./app/hooks";
import NewPassword from "./pages/NewPassword";
import SelfRegister from "./pages/SelfRegister";
import ScrubWarnings from "./pages/Main/UploadList/ScrubWarnings";
import AdminAccountsMain from "./pages/Admin/Accounts/AdminAccountsMain";
import AdminIntelliScrub from "./pages/Admin/Settings/IntelliScrub";
import AdminAccount from "./pages/Admin/Settings/Account";
import LitigatorScrubSettings from "./pages/Admin/Settings/LitigatorScrubSettings";
import Home from "src/components/Home";
import SelectDialer from "./components/Sysadmin-React-Islands/SelectDialer";
import Profile from "./pages/Profile";

import { useAppSelector } from "src/app/hooks";

/* Load application insight */
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({});
var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
  config: {
    connectionString: import.meta.env.VITE_APP_INSIGHTS_CONN_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

// In dev, leave VITE_APP_INSIGHTS_CONN_STRING env variable blank
// so we don't populate App Insights with dev usage
if (import.meta.env.VITE_APP_INSIGHTS_CONN_STRING) {
  appInsights.loadAppInsights();
}

function App() {
  const isDeveloper = false;

  // Get from the Redux store if the user is authenticated
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);

  // Get means to logout user
  const dispatch = useAppDispatch();
  const [logoutUser] = useLogoutUserMutation();

  const [showInactivityModal, setShowInactivityModal] = useState(false);

  const MS_IN_MINUTE = 60 * 1000;
  const COUNTDOWN_TIME = MS_IN_MINUTE; // 1 minute
  const SESSION_LENGTH = 15 * MS_IN_MINUTE; // 15 minutes
  const timeUntilCountdownModal = SESSION_LENGTH - COUNTDOWN_TIME; // 15 minutes minus 1 minute for countdown
  //const timeUntilCountdownModal = 10 * 1000; // for debugging use this to get countdown in 10 seconds

  // useInactivity returns a boolean value that tells us if the user is inactive
  // a useEffect then changes based on this value to trigger the modal
  let [isInactive, resetTimeout] = useInactivity(timeUntilCountdownModal);

  const loginURL = `/login`;

  // function to logout user
  const handleLogout = useCallback(
    (reason: string) => {
      console.log(`User wants to logout. Reason: ${reason}`);
      logoutUser({});
      dispatch(logout());
      setShowInactivityModal(false);

      let url = `${loginURL}?reason=${reason}`;
      if (window && window.top) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }
    },
    [dispatch, logoutUser, loginURL],
  );

  // function to stay logged in
  const handleStayLoggedIn = useCallback(async () => {
    console.log("user wants to stay logged in");
    try {
      const response = await callApi("users/isauthenticated", HttpMethod.GET);
      //Abhi: Sometimes the response may not have a message property
      if (!response?.message || response.message !== "Authorized") {
        dispatch(setIsAuthenticated());
        window.location.href = "/login?reason=error";
        return;
      }

      setShowInactivityModal(false);
      setModalCountdown(60);
      resetTimeout();
    } catch (error) {
      alert("Error during re-authentication. Please login again.");
      console.error("Error during re-authentication:", error);
      handleLogout("error");
    }
  }, [dispatch, handleLogout, resetTimeout]);

  // This effect will show the countdown modal
  // It fires when the isInactive and isAuthenticated
  // values change
  useEffect(() => {
    if (isInactive && isAuthenticated) {
      setShowInactivityModal(true);
    }
  }, [isInactive, isAuthenticated]);

  // Redirect to https if not already
  // We don't do this in our reverse proxy because
  // I am concerned that would break any API requests
  // that come to us today as http
  useEffect(() => {
    if (window.location.protocol !== "https:") {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
    }
  }, []);

  // Update the countdown display on the modal
  const [modalCountdown, setModalCountdown] = useState(60);
  useEffect(() => {
    let timer: number | undefined;
    if (showInactivityModal) {
      timer = window.setInterval(() => {
        setModalCountdown((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      if (modalCountdown <= 0) handleLogout("expired");
    }
    return () => clearInterval(timer);
  }, [showInactivityModal, modalCountdown, handleLogout]);

  return (
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
      onError={() => <h1>Something went wrong. Please try again later.</h1>}
    >
      <>
        {showInactivityModal && (
          <div className="modal d-block" tabIndex={-1}>
            <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">
                    {"Your session is about to expire!"}
                  </h5>
                </div>
                <div className="modal-body d-flex">
                  <i className="h1 text-danger bi bi-exclamation-triangle pe-3"></i>
                  <div>
                    You will be logged out in <span>{modalCountdown}</span>{" "}
                    seconds.
                    <br />
                    Do you want to stay signed in?
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleStayLoggedIn}
                  >
                    Yes, Keep me signed in
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => handleLogout("user")}
                  >
                    No, Sign me out
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showInactivityModal && (
          <div className="modal-backdrop fade show"></div>
        )}
        <div className="App">
          <HelmetProvider>
            <BrowserRouter>
              <RouteChangeListener>
                <ToastContainer />
                <Routes>
                  <Route
                    path="/"
                    element={
                      isDeveloper ? (
                        <Navigate to="/main/upload-list/:campaignId" replace />
                      ) : (
                        //<Navigate to="/compliance-guide/intro" replace />
                        <Navigate to="/login" replace />
                        //<ExternalRedirect to="https://classic.dncscrub.com/index.adp?afterLoginPage=https%3a%2f%2fapp.dncscrub.com%2fcompliance-guide%2fintro" />
                      )
                    }
                  />

                  <Route
                    path="/login"
                    element={
                      <WithLayout
                        Component={Login}
                        Layout={LoginLayout}
                        PageTitle="Login"
                      />
                    }
                  />
                  <Route
                    path="/login/reset-your-password"
                    element={
                      <WithLayout
                        Component={ResetPassword}
                        Layout={LoginLayout}
                        PageTitle="Reset Password"
                      />
                    }
                  />
                  <Route
                    path="/login/self-register"
                    element={
                      <WithLayout
                        Component={SelfRegister}
                        Layout={LoginLayout}
                        PageTitle="SelfRegister"
                      />
                    }
                  />
                  <Route
                    path="/login/new-password"
                    element={
                      <WithLayout
                        Component={NewPassword}
                        Layout={LoginLayout}
                        PageTitle="Choose New Password"
                      />
                    }
                  />

                  <Route
                    path="/home"
                    element={<WithLayout Component={Home} />}
                  />

                  <Route
                    path="/profile"
                    element={
                      <WithLayout Component={Profile} PageTitle="My Profile" />
                    }
                  />

                  {/* Main */}
                  <Route
                    path="/main/upload-list/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Do Not Call Upload"
                      />
                    }
                  />
                  <Route
                    path="/main/reassigned-scrub/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Reassigned Upload"
                      />
                    }
                  />

                  <Route
                    path="/main/project-settings/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Project Settings"
                      />
                    }
                  />
                  <Route
                    path="/main/campaign-settings/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Campaign Settings"
                      />
                    }
                  />

                  {/* Abhi: Tcl App stores adp links in the database. 
                Until full migration we are adding this so that both apps can coexist in harmony */}
                  <Route
                    path="/m-dncdnm-db.adp"
                    element={<Navigate replace to="/main/internal-dnc" />}
                  />
                  <Route
                    path="/main/internal-dnc/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Internal DNC"
                      />
                    }
                  />
                  <Route
                    path="/main/ebr-master/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="EBR Master"
                      />
                    }
                  />
                  <Route
                    path="/main/dnc-policy/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="DNC Policy"
                      />
                    }
                  />
                  <Route
                    path="/main/cross-account-info/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Cross Account Info"
                      />
                    }
                  />
                  {/* Abhi: Tcl App stores adp links in the database. 
                Until full migration we are adding this so that both apps can coexist in harmony */}
                  <Route
                    path="/m-send.adp"
                    element={<Navigate replace to="/main/send-policy" />}
                  />
                  <Route
                    path="/main/send-policy/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Send Policy"
                      />
                    }
                  />
                  <Route
                    path="/main/exemption-master/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Exemption Master"
                      />
                    }
                  />
                  <Route
                    path="/main/scrub-options/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Scrub Options"
                      />
                    }
                  />

                  <Route
                    path="/main/training-master/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Training Master"
                      />
                    }
                  />

                  {/* Abhi: Tcl App stores adp links in the database. 
                Until full migration we are adding this so that both apps can coexist in harmony */}
                  <Route
                    path="/m-quick-scrub.adp"
                    element={<Navigate replace to="/main/quickscrub" />}
                  />
                  <Route
                    path="/main/quickscrub/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="QuickScrub"
                      />
                    }
                  />
                  <Route
                    path="/main/quickscrubresults/:campaignId?"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="QuickScrub Results"
                      />
                    }
                  />

                  {/* Abhi: Tcl App stores adp links in the database. 
                Until full migration we are adding this so that both apps can coexist in harmony */}
                  <Route
                    path="/m-training.adp"
                    element={<Navigate replace to="/main/training" />}
                  />
                  <Route
                    path="/main/training"
                    element={
                      <WithLayout
                        Component={UploadListMain}
                        PageTitle="Training Master"
                      />
                    }
                  />

                  <Route
                    path="/warnings"
                    element={
                      <WithLayout
                        Component={ScrubWarnings}
                        PageTitle="Warnings"
                      />
                    }
                  />

                  {/* Reports */}
                  <Route
                    path="/reports/policies-sent"
                    element={
                      <WithLayout
                        Component={PoliciesSent}
                        PageTitle="Reports - Policies Sent"
                      />
                    }
                  />
                  <Route
                    path="/reports/investigate-phone-number"
                    element={
                      <WithLayout
                        Component={InvestigatePhoneNumber}
                        PageTitle="Investigate Phone Number"
                      />
                    }
                  />

                  {/* Users */}
                  <Route
                    path="/users"
                    element={<WithLayout Component={Users} />}
                  />
                  <Route
                    path="/add-user"
                    element={<WithLayout Component={AddUser} />}
                  />

                  {/* Tcl app links */}
                  <Route
                    path="/t/:slug"
                    element={<WithLayout Component={Tcl} />}
                  />

                  {/* Compliance Guide */}
                  <Route
                    path="/compliance-guide/intro"
                    element={
                      <WithLayout
                        Component={CGIntro}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Intro"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/regulatory-guide"
                    element={
                      <WithLayout
                        Component={CGRegulatoryGuide}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Regulatory Guide"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/regulatory-charts"
                    element={
                      <WithLayout
                        Component={CGCharts}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Charts"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/regulatory-watch"
                    element={
                      <WithLayout
                        Component={CGRegulatoryWatch}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Regulatory Watch"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/dnc-map"
                    element={
                      <WithLayout
                        Component={CGDNCMap}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide DNC Map"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/statutes"
                    element={
                      <WithLayout
                        Component={CGStatutes}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Statutes"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/faq"
                    element={
                      <WithLayout
                        Component={CGFaqIndex}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide FAQ"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/compliance-wizard"
                    element={
                      <WithLayout
                        Component={CGComplianceWizard}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Wizard"
                      />
                    }
                  />
                  {/* <Route
              path="/compliance-guide/exemption-master"
              element={<WithLayout Component={CGExemptionMaster} Layout={ComplianceGuideLayout} />}
            /> */}
                  <Route
                    path="/compliance-guide/glossary"
                    element={
                      <WithLayout
                        Component={CGGlossary}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Glossary"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/consent-chart"
                    element={
                      <WithLayout
                        Component={CGConsentChart}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Consent Chart"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/litigator-research"
                    element={
                      <WithLayout
                        Component={CGLitigatorResearch}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Litigator Research"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/litigator-scrub"
                    element={
                      <WithLayout
                        Component={CGLitigatorScrub}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Litigator Scrub"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/ftc-complaint-search"
                    element={
                      <WithLayout
                        Component={CGFTCComplaintSearch}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide FTC Complaint Search"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/submit-litigator"
                    element={
                      <WithLayout
                        Component={CGSubmitLitigator}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Submit Litigator"
                      />
                    }
                  />
                  <Route
                    path="/compliance-guide/other-tools"
                    element={
                      <WithLayout
                        Component={CGOtherTools}
                        Layout={ComplianceGuideLayout}
                        PageTitle="Compliance Guide Other Tools"
                      />
                    }
                  />

                  {/* Admin (don't conflate with sysadmin!) */}
                  <Route
                    path="/admin/contact-info"
                    element={
                      <WithLayout
                        Component={AdminAccountsMain}
                        PageTitle="Admin Contact Info"
                      />
                    }
                  />

                  {/* Sysadmin components to embed in Tcl app via React Island pattern*/}
                  <Route
                    path="/sysadmin/island-proof-of-concept"
                    element={
                      <WithLayout
                        Component={SelectDialer}
                        PageTitle="Select Dialer"
                      />
                    }
                  />
                  <Route
                    path="/admin/cross-accounts"
                    element={
                      <WithLayout
                        Component={AdminAccountsMain}
                        PageTitle="Admin Cross Accounts"
                      />
                    }
                  />
                  <Route
                    path="/admin/client-access"
                    element={
                      <WithLayout
                        Component={AdminAccountsMain}
                        PageTitle="Admin Client Access"
                      />
                    }
                  />
                  <Route
                    path="/admin/sub-accounts"
                    element={
                      <WithLayout
                        Component={AdminAccountsMain}
                        PageTitle="Admin Sub Accounts"
                      />
                    }
                  />
                  <Route
                    path="/admin/intelliscrub"
                    element={
                      <WithLayout
                        Component={AdminIntelliScrub}
                        PageTitle="Admin IntelliScrub Settings"
                      />
                    }
                  />
                  <Route
                    path="/admin/litigator-scrub"
                    element={
                      <WithLayout
                        Component={LitigatorScrubSettings}
                        PageTitle="Litigator Scrub Settings"
                      />
                    }
                  />
                  <Route
                    path="/admin/account"
                    element={
                      <WithLayout
                        Component={AdminAccount}
                        PageTitle="Admin Account Settings"
                      />
                    }
                  />
                  <Route
                    path="/error"
                    element={<WithLayout Component={Error} PageTitle="Error" />}
                  />
                  <Route
                    path="*"
                    element={
                      <WithLayout
                        Component={NotFound}
                        PageTitle="Page Not Found"
                      />
                    }
                  />
                </Routes>
              </RouteChangeListener>
            </BrowserRouter>
          </HelmetProvider>
        </div>
      </>
    </AppInsightsErrorBoundary>
  );
}

export default App;
