import * as React from "react";

type WarningProps = {
  message?: string;
};

const Warning: React.FC<WarningProps> = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <div
      className="warning"
      style={{
        backgroundColor: "lightgray",
        color: "red",
        padding: "3px",
        marginBottom: "10px",
        borderRadius: "2px",
      }}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  );
};

export default Warning;
