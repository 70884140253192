import styles from "./Login.module.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLoginUserMutation } from "src/services/authApi";
import { useAppDispatch } from "src/app/hooks";
import { setAuthInfo } from "src/features/authSlice";
import Cookies from "js-cookie";
import Warning from "src/components/Warning";
import { useForm } from "react-hook-form";
import storageService from "src/services/storageService";
import SaveInProgress from "src/components/SaveInProgress";
import { HttpMethod, callApi } from "src/services/apiService";

type LoginFormFields = {
  acctId: string;
  username: string;
  password: string;
};

const Login = () => {
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [mfaMethod, setMfaMethod] = useState<string | null>(null);
  const [validationCode, setValidationCode] = useState<string>("");
  const [showWarning, setShowWarning] = useState<string>("");
  const [searchParams] = useSearchParams();
  const [loginId, setLoginId] = useState<string>("");
  const [loginResponse, setLoginResponse] = useState<any>("");
  const [retryAttempt, setRetryAttempt] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginFormFields>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginUser] = useLoginUserMutation();

  useEffect(() => {
    const reason = decodeURIComponent(searchParams.get("reason") || "");
    if (reason) {
      switch (reason.toLowerCase()) {
        case "expired":
          setShowWarning("Your session has expired. \nPlease log in again.");
          break;
        case "selfregistered":
          setShowWarning(
            "Your new account was created. \nPlease login with the username and password you chose.",
          );
          break;
        default:
          setShowWarning("You have been logged out.");
          break;
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setValue("acctId", Cookies.get("acctId") || "");
  }, [setValue]);

  const handleLogin = async (formData: LoginFormFields) => {
    setSaveInProgress(true);
    const { acctId, username, password } = formData;

    try {
      const response = await loginUser({
        acctId: acctId.toUpperCase(),
        username,
        password,
      }).unwrap();
      setLoginResponse(response);
      if (response.mfa && response.mfa !== "none") {
        // MFA required
        setRetryAttempt(0);
        setShowMFA(true);
        setMfaMethod(response.mfa);
        setLoginId(response.loginId);
        setShowWarning(
          `A code has been sent to your ${response.mfa}<br />${response.mfaValue}.`,
        );
      } else {
        // No MFA required
        completeLogin(response);
      }
    } catch (err: any) {
      const errorMessage = err.data?.message || "Login failed.";
      setShowWarning(errorMessage);
    } finally {
      setSaveInProgress(false);
    }
  };

  const handleValidation = async () => {
    setSaveInProgress(true);

    try {
      const apiUrl = `mfa/VerifyCode?loginId=${loginId}&type=${mfaMethod}&code=${validationCode}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        completeLogin(loginResponse);
      } else if (response && response.type.toLowerCase() === "failed") {
        setRetryAttempt(retryAttempt + 1);
        setShowWarning("Validation code is incorrect.<br />Please try again.");
      }
    } catch (error) {
      setShowWarning("Unable to validate. Please try again.");
    } finally {
      setSaveInProgress(false);
      if (retryAttempt >= 3) {
        setShowWarning("You have exceeded the maximum number of attempts.");
        clearLoginState();
      }
    }
  };

  const handleBackButtonClick = () => {
    clearLoginState();
    setShowWarning("Login attempt cancelled.");
  };

  const resendCode = async () => {
    setSaveInProgress(true);

    try {
      const apiUrl = `mfa/ResendVerificationCode?loginId=${loginId}&type=${mfaMethod}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        setRetryAttempt(0);
        setShowWarning(
          `Verification code has been resent to your ${mfaMethod}.`,
        );
      } else if (response && response.type.toLowerCase() === "failed") {
        setShowWarning(
          "There was an issue sending the code. Please try again.",
        );
      }
    } catch (error) {
      setShowWarning("Unable to validate. Please try again.");
    } finally {
      setSaveInProgress(false);
      if (retryAttempt >= 3) {
        setShowWarning("You have exceeded the maximum number of attempts.");
        clearLoginState();
      }
    }
  };

  const completeLogin = (data: any) => {
    dispatch(
      setAuthInfo({
        acctId: data.acctId,
        acctName: data.acctName,
        username: data.username,
        fullName: data.fullName,
        quickScrub: data.quickScrub,
        role: data.role,
        isSysadmin: data.isSysadmin,
        loginId: data.loginId,
        csrfToken: data.csrfToken,
        hasTrainingFeature: data.hasTrainingFeature,
        homePage: data.homePage,
        acctMessage: data.acctMessage,
      }),
    );

    const defaultPage = data.role === 4 ? "/login/self-register" : "/home";
    const lastLocationKey = `lastLocation_${data.acctId}_${data.username}`;
    let lastLocation = storageService.getItem<string>(lastLocationKey);

    if (lastLocation?.toLowerCase().startsWith("/login")) {
      storageService.removeItem(lastLocationKey);
      lastLocation = null;
    }

    if (lastLocation && data.role !== 4) {
      navigate(lastLocation);
    } else {
      navigate(defaultPage);
    }
  };

  const clearLoginState = () => {
    setShowMFA(false);
    setValidationCode("");
    setMfaMethod(null);
  };

  return (
    <form onSubmit={handleSubmit(showMFA ? handleValidation : handleLogin)}>
      <Warning message={showWarning} />
      {!showMFA && (
        <>
          <input
            {...register("acctId", {
              required: "Account ID is required",
              onChange: (e) => setValue("acctId", e.target.value.toUpperCase()),
            })}
            className={`${styles["form-control"]} ${
              errors.acctId ? styles["error-input-box"] : ""
            }`}
            placeholder="Account"
          />
          {errors.acctId?.message && (
            <div className={styles["error-message"]}>
              {errors.acctId.message}
            </div>
          )}

          <input
            {...register("username", { required: "Username is required" })}
            className={`${styles["form-control"]} ${
              errors.username ? styles["error-input-box"] : ""
            }`}
            placeholder="Username"
            autoComplete="username"
          />
          {errors.username?.message && (
            <div className={styles["error-message"]}>
              {errors.username.message}
            </div>
          )}

          <input
            {...register("password", { required: "Password is required" })}
            className={`${styles["form-control"]} ${
              errors.password ? styles["error-input-box"] : ""
            }`}
            placeholder="Password"
            type="password"
            autoComplete="current-password"
          />
          {errors.password?.message && (
            <div className={styles["error-message"]}>
              {errors.password.message}
            </div>
          )}
        </>
      )}

      {showMFA && (
        <>
          <input
            value={validationCode}
            onChange={(e) => setValidationCode(e.target.value)}
            className={`${styles["form-control"]}`}
            placeholder="Enter validation code"
          />
          <div className="mt-2">
            Didn't receive a code?{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                resendCode();
              }}
            >
              Resend code
            </a>
          </div>
          <div className="mt-2">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleBackButtonClick();
              }}
            >
              Back to login
            </a>
          </div>
        </>
      )}

      <button className={styles["login-btn"]} type="submit">
        <SaveInProgress isVisible={saveInProgress} loadingText="" />
        {!saveInProgress && <>{showMFA ? "Validate" : "Login"}</>}
      </button>
      {!showMFA && (
        <div className="mt-3 mb-0">
          <Link to="/login/reset-your-password">Forgot your password?</Link>
        </div>
      )}
      <div className="mt-3 mb-0">
        <a href="https://classic.dncscrub.com/">Switch to DNCScrub Classic</a>
      </div>
    </form>
  );
};

export default Login;
