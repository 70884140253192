import { useState, useEffect } from "react";
import { HttpMethod, callApi } from "src/services/apiService";
import IMfaSettings from "src/interfaces/IMfaSettings";
import "./Profile.module.css";

const Profile = () => {
  const [data, setData] = useState<IMfaSettings>();
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);

  // Modal states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [modalType, setModalType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalActionLabel, setModalActionLabel] = useState("");
  const [modalPlaceholder, setModalPlaceholder] = useState("");
  const [modalError, setModalError] = useState("");

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    try {
      const apiUrl = `mfa`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      setData(response);

      // Initialize state based on API data
      setIsMfaEnabled(response.isEnabled);
    } catch (error) {
      console.error("Failed to fetch MFA settings:", error);
    }
  }

  const toggleMfa = async (value: boolean) => {
    if (!data?.emailEnabled && !data?.phoneEnabled) {
      alert("You need to setup at least 1 type of MFA before enabling MFA.");
      return;
    }

    try {
      const apiUrl = `mfa/enabledisable?type=mfa&value=${value}`;
      await callApi(apiUrl, HttpMethod.POST);

      fetchSettings();
    } catch (error) {
      console.error(`Failed to toggle MFA:`, error);
      alert(`Failed to ${value ? "enable" : "disable"} MFA. Please try again.`);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalError("");
    setInputValue("");
  };

  const handleSubmit = async () => {
    const code = inputValue;
    var apiUrl = "";
    switch (modalType.toLowerCase()) {
      case "email":
        apiUrl = `mfa/VerifyCode?loginId&type=email&code=${code}`;
        break;
      case "phone":
        apiUrl = `mfa/VerifyCode?loginId&type=phone&code=${code}`;
        break;
      case "changeemail":
        apiUrl = `mfa/ChangeEmail?email=${code}`;
        break;
      case "changephone":
        apiUrl = `mfa/ChangePhone?phone=${code}`;
        break;
      default:
      //Update error message here
    }
    if (apiUrl != "") {
      try {
        const response = await callApi(apiUrl, HttpMethod.POST);
        if (response && response.type.toLowerCase() === "success") {
          //alert(`${modalType} verified successfully!`);
          setModalError("");
          fetchSettings();
          closeModal();
        } else if (response && response.type.toLowerCase() === "failed") {
          setModalError(response.message);
        }
      } catch (error) {
        console.error(`Failed to verify ${modalType}:`, error);
        setModalError(`Failed to verify ${modalType}. Please try again.`);
      }
    }
  };

  const verifyEmail = async () => {
    try {
      const apiUrl = `mfa/SendEmailVerification`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        showModal(
          "email",
          "Verification Email Sent",
          "Verify",
          "Enter email verification code",
        );
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to verify ${modalType}:`, error);
      alert(`Failed to verify ${modalType}. Please try again.`);
    }
  };

  const verifyPhone = async () => {
    try {
      const apiUrl = `mfa/SendPhoneVerification`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        showModal(
          "phone",
          "Verification Code Sent",
          "Verify",
          "Enter verification code from your phone",
        );
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to verify ${modalType}:`, error);
      alert(`Failed to verify ${modalType}. Please try again.`);
    }
  };

  const showModal = (
    type: string,
    title: string,
    actionLabel: string,
    placeholder: string,
  ) => {
    setModalType(type);
    setModalTitle(title);
    setModalActionLabel(actionLabel);
    setModalPlaceholder(placeholder);
    setIsModalOpen(true);
  };

  const setDefaultMFA = async (type: string) => {
    try {
      const apiUrl = `mfa/SetDefaultMFA?type=${type}`;
      const response = await callApi(apiUrl, HttpMethod.POST);
      if (response && response.type.toLowerCase() === "success") {
        fetchSettings();
      } else if (response && response.type.toLowerCase() === "error") {
        alert(response.error);
      }
    } catch (error) {
      console.error(`Failed to set default ${type}:`, error);
      alert(`Failed to set ${type} as default. Please try again.`);
    }
  };

  return (
    <>
      <div className="ps-5 pe-5 pt-2">
        <h4>Profile</h4>
        <div className="card">
          <div className="card-header">Multi-Factor Authentication</div>
          <div className="card-body">
            {/* Enable/Disable MFA */}
            <div className="row align-items-center ms-1 mb-3">
              Enhance your online security by adding an extra layer of
              authentication to your account.
            </div>
            <div className="row align-items-center mb-3">
              <div className="col-md-3 col-4">
                <button
                  className="btn btn-primary"
                  disabled={
                    !data?.emailEnabled &&
                    !data?.phoneEnabled &&
                    !data?.appEnabled
                  }
                  onClick={() => toggleMfa(!isMfaEnabled)}
                >
                  {isMfaEnabled ? "Disable" : "Enable"} MFA
                </button>
              </div>
              {!data?.emailEnabled &&
                !data?.phoneEnabled &&
                !data?.appEnabled && (
                  <div className="col-md-9 col-8 text-danger">
                    You need to setup at least 1 type of MFA before enabling MFA
                    on this account.
                  </div>
                )}
            </div>

            <div className="row align-items-center mb-3">
              <div className="col-md-3 col-4">
                <span>Email</span>
              </div>
              <div className="col-md-6 col-5 text-muted">
                {data?.email || "No email provided"}{" "}
                <button
                  className="btn btn-link p-0 text-decoration-underline"
                  onClick={() =>
                    showModal(
                      "changeEmail",
                      "Change Account Email",
                      "Change",
                      "Enter updated account email",
                    )
                  }
                >
                  Change
                </button>
              </div>
              <div className="col-md-3 col-3">
                {data?.emailEnabled ? (
                  data?.defaultMethod === "email" ? (
                    <button className="btn btn-success" disabled>
                      Primary
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      disabled={!data?.emailEnabled || !isMfaEnabled}
                      onClick={() => setDefaultMFA("email")}
                    >
                      Set as Primary
                    </button>
                  )
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={() => verifyEmail()}
                    disabled={!data?.email}
                  >
                    Enable Email
                  </button>
                )}
              </div>
            </div>

            {/* Text MFA */}
            <div className="row align-items-center mb-3">
              <div className="col-md-3 col-4">
                <span>Phone (Text)</span>
              </div>
              <div className="col-md-6 col-5 text-muted">
                {data?.phone || "No phone number provided"}{" "}
                <button
                  className="btn btn-link p-0 text-decoration-underline"
                  onClick={() =>
                    showModal(
                      "changePhone",
                      "Change Account Phone",
                      "Change",
                      "Enter updated phone number",
                    )
                  }
                >
                  Change
                </button>
              </div>
              <div className="col-md-3 col-3">
                {data?.phoneEnabled ? (
                  data?.defaultMethod === "phone" ? (
                    <button className="btn btn-success" disabled>
                      Primary
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      disabled={!data?.phoneEnabled || !isMfaEnabled}
                      onClick={() => setDefaultMFA("phone")}
                    >
                      Set as Primary
                    </button>
                  )
                ) : (
                  <button
                    className="btn btn-primary"
                    disabled={!data?.phone}
                    onClick={() => verifyPhone()}
                  >
                    Enable Phone
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "8px",
                width: "400px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
              }}
            >
              <h5>{modalTitle}</h5>
              <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder={modalPlaceholder}
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "20px 0",
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                }}
              />
              {modalError && <div style={{ color: "red" }}>{modalError}</div>}

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <button
                  onClick={closeModal}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#ccc",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  {modalActionLabel}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
