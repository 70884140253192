import * as React from "react";
import Styles from "./ProfileDropdown.module.css";
import { selectAuth, logout } from "src/features/authSlice";
import { useAppSelector, useAppDispatch } from "src/app/hooks";
import { useLogoutUserMutation } from "src/services/authApi";
import { Link } from "react-router-dom";

interface ProfileDropdownProps {
  showSalesReport: boolean;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  showSalesReport,
}) => {
  const [logoutUser] = useLogoutUserMutation();
  const dispatch = useAppDispatch();
  const { fullName, isSysadmin, acctName } = useAppSelector(selectAuth);

  const handleLogout = async () => {
    try {
      await logoutUser({}); // call the logout API
      dispatch(logout()); // Clear the redux store's auth info
      console.log("Logout successful");

      // For some reason when navigate is used, Redux Store still
      // thinks I am logged in when the sendKeepAliveAndReset method
      // fires in useInactivity.ts. So I am using window.location.href
      //navigate("/login?reason=user");
      window.location.href = "/login?reason=user";
    } catch (error) {
      console.log("Failed to log out");
    }
  };

  return (
    <div className="d-flex dropdown">
      <button
        className="nav-link text-white dropdown-toggle d-flex justify-content-center align-items-center gap-lg-2"
        aria-expanded="false"
        data-bs-toggle="dropdown"
        aria-label="Toggle theme"
        id="dropdownMenuLink"
      >
        <div>
          {/* <img alt="useradmin icon" src={user.iconSrc} /> */}
          {/* <i className="bi bi-person-plus-fill"></i> */}
          <i className={`bi bi-person-circle ${Styles["profile-icon"]}`}></i>
        </div>
        <div className={`${Styles["person-dropdown"]} d-none d-lg-block`}>
          {fullName} <div>({acctName})</div>
        </div>
      </button>
      <ul
        className={`dropdown-menu dropdown-menu-end ${Styles["profile-menu"]}`}
        aria-labelledby="dropdownMenuLink"
      >
        {isSysadmin && (
          <>
            <li>
              {/* For now lets open sysadmin in a new window. This is what our support team is used to
              Sysadmin can also be embedded with url /t/sysadmin but unclear if we will use */}
              <a
                href="https://classic.dncscrub.com/app/sysadmin/"
                target="_blank"
                className="dropdown-item d-flex align-items-center gap-2"
                rel="noreferrer noopener"
              >
                <i className={`bi bi-gear`}></i>
                Sysadmin
              </a>
            </li>
          </>
        )}

        {(isSysadmin || showSalesReport) && (
          <>
            <li>
              <Link
                to="/t/sales-production-report"
                className="dropdown-item d-flex align-items-center gap-2"
              >
                <i className={`bi bi-file-earmark-spreadsheet`}></i>
                Sales Report
              </Link>
            </li>
            <li>
              <hr />
            </li>

            <li>
              <Link
                to="/profile"
                className="dropdown-item d-flex align-items-center gap-2"
              >
                <i className={`bi bi-person`}></i>
                Profile
              </Link>
            </li>
            <li>
              <hr />
            </li>
          </>
        )}

        <li>
          <button
            className="dropdown-item d-flex align-items-center gap-2"
            onClick={handleLogout}
          >
            <i className={`bi bi-door-closed`}></i>
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileDropdown;
