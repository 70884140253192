export const formatNumber = (num: number) => {
  return new Intl.NumberFormat("en-US").format(num);
};

export function formatCardDateTime(dateString: string): string {
  const date = new Date(dateString);

  const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().slice(2)}`;
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hours % 12 || 12}:${minutes}:${seconds} ${amPm}`;

  return `${formattedDate} ${formattedTime}`;
}

export const formatDateTime = (utcDateString: string): string => {
  if (!utcDateString || isNaN(Date.parse(utcDateString + "Z"))) {
    return "";
  }

  const utcDate = new Date(utcDateString + "Z");
  const localDate = new Date(utcDate.getTime());

  const formattedDate = localDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const formattedTime = localDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return `${formattedDate} at ${formattedTime}`;
};

export const formatDate = (dateString: string) => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "";
  }

  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};
